import React from "react";
import { useTranslation } from 'react-i18next';

const TechnologySkills = () => {
  const { t } = useTranslation();

  const techskillGroups = [
    6,2, 4
  ]

  const filledGroups = [];


  techskillGroups.forEach((group, index) => {
    const entries = [];
    for (let i = 1; i <= group; i++) {
      const descKey = t('technologySkills.'+(index+1)+'.'+i+'.desc');
      entries.push({
        name: t('technologySkills.'+(index+1)+'.'+i+'.title'),
        desc: t(descKey),
        percent: t('technologySkills.'+(index+1)+'.'+i+'.percent'),
      });
    }
    filledGroups.push({
      title: t('technologySkills.'+(index+1)+'.title'),
      entries: entries
    });
  })


  
  return (
    <section id="technologySkills" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">
            {t('technologySkills.intro')}  
          </span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          {t('technologySkills.title')}
        </h2>

        {filledGroups.length > 0 && filledGroups.map((group, index) => (
          <>
            <h2 className="text-7 fw-600 mb-4 pb-2 mt-5">
              {group.title}
            </h2>
            <div className="row gx-5">
              {group.entries.length > 0 &&
                group.entries.map((skill, index) => (
                  <div className="col-md-6 wow fadeInUp" key={index}>
                    <p className="fw-500 text-start mb-2">
                      {skill.name}{" "}
                      <span className="float-end">{skill.percent}%</span>
                    </p>
                    
                    <div className="progress progress-sm mb-1">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: skill.percent + "%" }}
                        aria-valuenow={skill.percent}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <p className="fw-500 text-start mb-5 text-muted">
                      {skill.desc}{" "}
                    </p>
                  </div>
                ))}
            </div>
          </>
        ))}

      </div>
    </section>
  );
};

export default TechnologySkills;
