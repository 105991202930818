import React from "react";
//import resumeFile from "../documents/resume.pdf";
import { useTranslation } from 'react-i18next';


const ResumeBlock = ({ title, entries }) => {
  return (
    <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.2s">
      <h2 className="text-7 fw-600 mb-4 pb-2">
        {title}
      </h2>
      <div className="border-start border-2 border-primary ps-3">
        {entries.length > 0 &&
          entries.map((value, index) => (
            <div key={index}>
              <h3 className="text-5">
                <div className="row">

                <div className="col-sm-7">
                  {value.title}
                </div>
                <div className="col-sm-5 text-end">
                  {value.yearRange}
                </div>
                </div>
              </h3>
              <p className="mb-2">
                {value.place}
              </p>
              <p className="text-muted">{value.desc}</p>
              <hr className="my-4" />
            </div>
          ))}
      </div>
    </div>
  )
}

const Resume = () => {
  const { t } = useTranslation();

  const educationEntriesCount = 2;
  const educationDetails = [];
  for (let i = 1; i <= educationEntriesCount; i++) {
    educationDetails.push({
      yearRange: t('resume.education.'+i+'.date'),
      title: t('resume.education.'+i+'.title'),
      place: t('resume.education.'+i+'.institute'),
      desc: t('resume.education.'+i+'.desc'),
    });
  }

  const experienceDetails = []
  const experienceEntriesCount = 6;
  for (let i = 1; i <= experienceEntriesCount; i++) {
    experienceDetails.push({
      yearRange: t('resume.experience.'+i+'.date'),
      title: t('resume.experience.'+i+'.title'),
      place: t('resume.experience.'+i+'.institute'),
      desc: t('resume.experience.'+i+'.desc'),
    });
  }

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">
            {t('resume.intro')}
          </span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          {t('resume.title')}
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          <ResumeBlock title={t('resume.experience.title')} entries={experienceDetails} />
          <ResumeBlock title={t('resume.education.title')} entries={educationDetails} />
        </div>
        {/* My Skills 
        <p className="text-center mt-5 wow fadeInUp" style={{backgroundColor: "red"}}>
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            <i className="fas fa-download me-2" />
            CHANGE FILE !!!
            {t('resume.downloadCV')}
          </a>
        </p>
        */}
      </div>
    </section>
  );
};

export default Resume;
