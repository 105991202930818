import React from "react";
import { useTranslation } from 'react-i18next';

const ServiceBlock = ({ services }) => {
  return (
    <div className="row gy-5 mt-5">
    {services.length > 0 &&
      services.map((service, index) => (
        <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
          <div className="featured-box text-center px-md-4">
            <div className="featured-box-icon text-primary text-13">
              {" "}
              <i className={service.icon} />
            </div>
            <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
            <p className="text-muted mb-0">{service.desc} </p>
          </div>
        </div>
      ))}
    </div>
  )
};


const Consulting = () => {
  // services details
  const { t } = useTranslation();

  const developmentServicesCount = 6;
  const devServices = [];
  for (let i = 1; i <= developmentServicesCount; i++) {
    devServices.push({
      name: t('consulting.'+i+'.title'),
      desc: t('consulting.'+i+'.desc'),
      icon: t('consulting.'+i+'.icon'),
    });
  }
  
  return (
    <section id="consultingServices" className="section bg-light">
      <div className="container mb-5">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">
            {t('consulting.intro')}
          </span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-2 wow fadeInUp">
        {t('consulting.title')}
        </h2>
        <p className="text-muted mb-0 text-center wow fadeInUp">
          {t('consulting.subtitle')}
        </p>
        {/* Heading end*/}
        <div className="row gy-5 mt-2 mb-3">
          <ServiceBlock services={devServices} />
        </div>
      </div>
    </section>
  );
};

export default Consulting;
