const AllSections =[
    {
        file: 'Intro',
        renderMenu: true,
        section: 'home'
    },
    {
        file: 'About',
        renderMenu: true,
        section: 'about'
    },
    {
        file: 'DevelopmentServices',
        renderMenu: true,
        section: 'developmentServices'
    },
    {
        file: 'TechnologySkills',
        renderMenu: true,
        section: 'technologySkills'
    },
    {
        file: 'ConsultingServices',
        renderMenu: true,
        section: 'consultingServices'
    },
    {
        file: 'Projects',
        renderMenu: true,
        section: 'projects'
    },
    {
        file: 'CallToAction',
        renderMenu: false,
        section: 'callToAction'
    },
    {
        file: 'FAQs',
        renderMenu: true,
        section: 'faqs'
    },    
    {
        file: 'Contact',
        renderMenu: true,
        section: 'contact'
    }
]


const Sections =[
    {
        file: 'Intro',
        renderMenu: true,
        section: 'home'
    },
    {
        file: 'About',
        renderMenu: true,
        section: 'about'
    },
    {
        file: 'DevelopmentServices',
        renderMenu: true,
        section: 'developmentServices'
    },
    {
        file: 'TechnologySkills',
        renderMenu: true,
        section: 'technologySkills'
    },
    {
        file: 'ConsultingServices',
        renderMenu: true,
        section: 'consultingServices'
    },
    {
        file: 'Resume',
        renderMenu: true,
        section: 'resume'
    },
    {
        file: 'CallToAction',
        renderMenu: false,
        section: 'callToAction'
    },   
    {
        file: 'Contact',
        renderMenu: true,
        section: 'contact'
    }
]
export default Sections 