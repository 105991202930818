const links = [
    {
        className: 'social-icons-linkedin',
        url: 'https://www.linkedin.com/in/alejandro-ruiz-ramirez/',
        icon: 'fa-brands fa-linkedin-in',
        title: 'LinkedIn'
    }, {
        className: 'social-icons-github',
        url: 'https://github.com/ykmg',
        icon: 'fab fa-github',
        title: 'GitHub'
    }
    /*
    , {
        className: 'social-icons-twitter',
        url: 'https://x.com/_bash_bear?s=21&t=Qf96Wi1bD_P5dOKcqzFvkQ',
        icon: 'fa-brands fa-x-twitter',
        title: 'X / Twitter'
    }, {
        className: 'social-icons-wordpress',
        url: 'https://bashbear.com/',
        icon: 'fab fa-wordpress',
        title: 'Blog'
    }
    */
]

export default links