import About from './About';
import CallToAction from './CallToAction';
import ConsultingServices from './ConsultingServices';
import DevelopmentServices from './DevelopmentServices';
import TechnologySkills from './TechnologySkills';
import Contact from './Contact';
import FAQs from './FAQs';
import Resume from './Resume';
import Intro from './Intro';

const SectionFiles = {
    About,
    CallToAction,
    TechnologySkills,
    DevelopmentServices,
    ConsultingServices,
    Resume,
    Contact,
    FAQs,
    Intro
}

function SectionsBlock({sections}) {
    if(!sections) return null;
    return (
        <div id="content" role="main">
            {sections.map((section, index) => {
                const Section = SectionFiles[section.file];
                return <Section key={index} {...section} />
            })}
        </div>
    );
};

export default SectionsBlock;

