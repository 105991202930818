import "./App.scss";
import WOW from "wowjs";
import Countdown from 'react-countdown';
import { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
 
function Maintenance() {
  
  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>00:00:00 - Please refresh</span>;
    } else {
      // Render a countdown
      return <span>{days} days, {hours} hours, {minutes} minutes and {seconds} seconds</span>;
    }
  };

  return (
    <> 
      <div style={{ position: "relative" }}>
        <div id="main-wrapper" style={{ height: "100vh"}}>
          <div id="content" role="main" style={{height: "100%", paddingTop: "150px"}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <div className="text-center">
                    <img src="images/yannikmoog-logo.png" alt="logo" className="img-fluid" style={{ maxWidth: "200px" }} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <h2 className="font-weight-bold">This site will be available soon!</h2>
                    <p className="lead">
                      Please check back again in <Countdown 
                        date={new Date("2024-03-01 00:00:00")} 
                        renderer={CountdownRenderer}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {/* countdown until maintenance ends */}
            </div>
          </div>
        </div>
        {/* back to top */}
      </div>
    </>
  );
}

export default Maintenance;
