import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

const ImprintModal = ({ projectDetails, setIsOpen }) => {


  const { t } = useTranslation();

  
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );


  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>    
        <div className="project-details-modal">
          <div
            className="modal fade bg-dark-1 show"
            style={{ display: "block" }}
            tabIndex={-1}
            aria-hidden="true"
            onClick={() => setIsOpen(false)}
          >
            <div
              className="modal-dialog modal-xl"
              onClick={(e) => e.stopPropagation()}
            >
              <div className={"modal-content rounded-0"}>
                <div className="modal-body" style={{color:"black"}}>
                  <button
                    type="button"
                    className={"btn-close "}
                    onClick={() => setIsOpen(false)}
                  />
                  <div className={"container ajax-container "}>
                    
                    <div className="row g-4">
                      <div className="col-md-12">
                        <h2 className={"text-6 fw-600 text-center mb-4"}>
                          {t('imprint.title')}
                        </h2>
                      </div>
                    
                      <div className="col-md-12">
                        <h4 className={"text-4 fw-600"}>
                          {t('imprint.serviceProvider')}
                        </h4>
                        <p className="p-2">
                          {t('imprint.serviceProviderText1')}<br/>
                          {t('imprint.serviceProviderText2')}<br/>
                          {t('imprint.serviceProviderText3')}<br/>
                          {t('imprint.serviceProviderText4')}
                        </p>
                        </div>
                        <div className="col-md-12">
                        <h4 className={"text-4 fw-600"}>
                          {t('imprint.contact')}
                        </h4>
                        <p className="p-2">
                          {t('imprint.contactText1')}<br/>
                          {t('imprint.contactText2')}
                        </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default ImprintModal;
