import React, { useEffect, useRef, useState } from "react";
import ImprintModal from "../common/ImprintModal";
import { useTranslation } from 'react-i18next';

const FooterLink = ({ href, text, onClick, classes }) => {
  return(
    <span className={"ms-2"}>
    <a className={"fw-600 " +classes ? classes : "" } href={href} onClick={onClick}>
      {text}
    </a>
    </span>
  )
}



const Footer = () => {

  const [isOpen, setIsOpen] = useState(false);
  const { i18n, t } = useTranslation();

  return (
    <footer id="footer" className="section bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              {t('footer.text')}
            </p>
          </div>
          
          <div className="col-lg-3 wow fadeInUp">
          <p className="mb-0 text-center text-lg-end">
                <FooterLink href="/lang" text={<>
                  <i className="fas fa-globe me-2" />
                  {t('general.switchLanguage')}</>} 
                  onClick={(e)=>{
                    e.preventDefault();
                    i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
                }}></FooterLink>
               
              </p>
          </div>
          <div className="col-lg-3 wow fadeInUp">
            
              
             
              <p className="mb-0 text-center text-lg-end">
                <FooterLink 
                  classes="iubenda-noiframe iubenda-embed"
                  href="https://www.iubenda.com/privacy-policy/36542168" text={t('footer.imprintPrivacy')}></FooterLink>
                <FooterLink classes="iubenda-noiframe iubenda-embed"
                 href="https://www.iubenda.com/privacy-policy/36542168/cookie-policy" text={t('footer.cookiePolicy')}></FooterLink>
               
              </p>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isOpen && (
        <ImprintModal
          setIsOpen={setIsOpen}
        ></ImprintModal>
      )}
    </footer>
  );
};

export default Footer;
