const translations = {
    "intro.intro": "Hi, I'm",
    "intro.title1": "Yannik",
    "intro.title2": "A Cloud Architect",
    "intro.title3": "A Software Developer",
    "intro.title4": "A DevOps Engineer",
    "intro.title5": "An IT-Consultant",
    "intro.title6": "an IT Freelancer",
    "intro.subtitle": "based in Germany and Luxemburg",
    "intro.actionbutton": "View my Services",
    "intro.contactbutton": "Get in touch",
    "about.intro": "About me",
    "about.title": "Know Me More",
    "about.hiIam": "Hi, I'm",
    "about.name": "Yannik Moog",
    "about.description": "I'm a Freelancer based in Germany and Luxemburg. I'm a Cloud Architect, Software Engineer, DevOps Engineer and IT-Consultant. I'm working with the latest technologies and I'm always looking for new challenges.",
    "about.yearsIntro": "Years of",
    "about.yearsExperience": "Experience",
    "about.yearsNumber": "12",
    "about.label.name": "Name:", 
    "about.value.name": "Yannik Moog",
    "about.label.age": "Date of birth:",
    "about.value.age": "14 May, 1988",
    "about.label.from": "From:",
    "about.value.from": "Trier, Germany",
    "about.label.email": "Email:",
    "about.label.education": "Education:",
    "about.value.education": "MSc. Business Informatics",
    "about.label.speaking": "Speaking:",
    "about.value.email": "info@moog-it.de",

    "portfolio.filter.all": "All",
    "portfolio.filter.consulting": "Consulting",
    "portfolio.filter.development": "Development",
    "portfolio.filter.devops": "DevOps",
    "portfolio.filter.embedded": "Embedded Systems",
    "portfolio.filter.other": "Other",

    "portfolio.p1.title": "The digital vineyard",
    "portfolio.p1.date": "2018",
    "portfolio.p1.projectInfo": "We unified separate ERP systems by digitizing wine production and integrating specific software, granting both Rotkäppchen Mumm Sektkellerein & Markgraf von Baden seamless access to vital data. Employing IoT and vineyard sensors, we captured real-time details on leaf moisture, temperature, and precipitation. These insights, analyzed in the SAP Cloud, optimized grape harvesting and maintained top-tier product quality, ensuring transparency from grape to glass. IoT integration expanded into procurement, shipping, and financial processes, streamlining the entire value chain. Our \"Connected Bottle App\" empowers consumers to trace a wine's journey, fostering transparent producer-consumer communication. This digital evolution, powered by custom software and IoT integration, seamlessly blends the Markgräflich Badischen Weinhauses' tradition with modern consumer needs.",
    "portfolio.p1.technologies": "SAP UI5, SAP Business Technology Platform, SAP Cloud Foundry, SAP IoT, Tinkerforge Hardware",
    "portfolio.p1.client": "Rotkäppchen-Mumm Sektkellereien & Markgraf von Baden via Sine Qua Non GmbH",
    "portfolio.p1.industry": "Agriculture, Wine Industry, Beverage Industry",
    "portfolio.p1.url": "https://midrange.de/der-digitale-weinberg/",

    "menu.home": "Home",
    "menu.about": "About",
    "menu.services": "Services",
    "menu.resume": "Resume",
    "menu.portfolio": "Portfolio",
    "menu.faq": "FAQ",
    "menu.contact": "Contact",
    "menu.consultingServices" : "Consulting Services",
    "menu.developmentServices" : "Development Services",
    "menu.technologySkills" : "Technology Skills",


    "services.intro": "What development services do I offer?",
    "services.development.title": "Software Development",
    "services.development.subtitle1": "You need apps, websites or backend services?",
    "services.development.subtitle2": "Or do you need support with your cloud deployment or CI/CD Pipeline?",
    "services.development.1.title": "Native Mobile Apps",
    "services.development.1.icon": "fas fa-mobile-alt",
    "services.development.1.desc": "Native iOS and Android apps with React Native.",
    "services.development.2.title": "Single Page Apps (SPA)",
    "services.development.2.icon": "fas fa-laptop-code",
    "services.development.2.desc": "State of the art Single Page Webapplications with ReactJS, MaterialUI, Bootstrap, SASS, CSS3 and HTML5.",
    "services.development.3.title": "APIs & Backend Services",
    "services.development.3.icon": "fas fa-server",
    "services.development.3.desc": "API-Driven Backend Services with NodeJS, Python, PHP or JAVA. Database Design with MySQL, MongoDB or PostgreSQL.",
    "services.development.4.title": "DevOps Automation (CI/CD)",
    "services.development.4.icon": "fas fa-code",
    "services.development.4.desc": "DevOps Scripting with Python, Bash. CI/CD with Github Actions and others. Docker and Kubernetes.",
    "services.development.5.title": "IoT & Embedded Systems",
    "services.development.5.icon": "fas fa-microchip",
    "services.development.5.desc": "IoT and Embedded Systems with Arduino, Raspberry Pi and ESP32.",
    "services.development.6.title": "Infrastructure as a Code",
    "services.development.6.icon": "fas fa-cloud",
    "services.development.6.desc": "Infrastructure as Code with Terraform. Cloud Services with AWS, Azure and Google Cloud.",

    "technologySkills.intro": "What technologies do I use?",
    "technologySkills.title": "My Technology Skills",
    "technologySkills.1.title": "Programming Languages & Frameworks",
    "technologySkills.1.1.title": "JavaScript",
    "technologySkills.1.1.desc": "ReactJS, React Native, NodeJS, NPM, ExpressJS (e.g MERN) ...",
    "technologySkills.1.1.percent": "98",
    "technologySkills.1.2.title": "Python",
    "technologySkills.1.2.desc": "Django, Flask, Pandas, Numpy, Matplotlib, Scikit-Learn, TensorFlow",
    "technologySkills.1.2.percent": "70",
    "technologySkills.1.3.title": "PHP",
    "technologySkills.1.3.desc": "Laravel, Symfony, CodeIgniter ...",
    "technologySkills.1.3.percent": "60",
    "technologySkills.1.4.title": "Java",
    "technologySkills.1.4.percent": "35",
    "technologySkills.1.4.desc": "Spring Boot, Hibernate, JPA ...",
    "technologySkills.1.5.title": "Infrastructure as Code",
    "technologySkills.1.5.percent": "73",
    "technologySkills.1.5.desc": "Terraform, CloudFormation, Ansible, Pulumi ...",
    "technologySkills.1.6.title": "OS, CI/CD, DevOps",
    "technologySkills.1.6.percent": "78",
    "technologySkills.1.6.desc": "Docker, Kubernetes, Github Actions, Linux, MacOS ...",

    "technologySkills.2.title": "Database Systems",
    "technologySkills.2.1.title": "SQL",
    "technologySkills.2.1.desc": "MySQL, PostgreSQL, MariaDB, SQLite, MS SQL Server, Oracle",
    "technologySkills.2.1.percent": "38",
    "technologySkills.2.2.title": "NoSQL",
    "technologySkills.2.2.desc": "MongoDB, DynamoDB, Redis, CouchDB, Cassandra",
    "technologySkills.2.2.percent": "72",

    "technologySkills.3.title": "Cloud Services & Platforms",
    "technologySkills.3.1.title": "AWS",
    "technologySkills.3.1.desc": "EC2, S3, Lambda, CloudFront, API Gateway, Route53, EKS ...",
    "technologySkills.3.1.percent": "62",
    "technologySkills.3.2.title": "Google Cloud Platform",
    "technologySkills.3.2.desc": "GKE, Cloud Run, Cloud Functions, Cloud Storage ...",
    "technologySkills.3.2.percent": "91",
    "technologySkills.3.3.title": "Azure",
    "technologySkills.3.3.desc": "Azure Kubernetes Service, Azure Functions, Azure Storage ...",
    "technologySkills.3.3.percent": "28",
    "technologySkills.3.4.title": "SAP Business Technology Platform",
    "technologySkills.3.4.desc": "SAP Cloud Foundry, SAP HANA ...",
    "technologySkills.3.4.percent": "45",

    "consulting.intro": "What consulting services do I offer?",
    "consulting.title": "IT Consulting",
    "consulting.subtitle": "With these services I can help you to build your next project.",
    "consulting.1.title": "SaaS & Cloud Solution Architecture",
    "consulting.1.icon": "fas fa-server",
    "consulting.1.desc": "I can help you to build your next SaaS Software Architecture, choose the right technologies and services and to build a scalable, secure and reliable architecture.",
    "consulting.2.title": "Development Operations",
    "consulting.2.icon": "fas fa-code",
    "consulting.2.desc": "I can help you to automate your DevOps processes, to build a CI/CD Pipeline with Github Actions or other tools.",
    "consulting.3.title": "Agile Project Management & agile Software Development",
    "consulting.3.icon": "fas fa-project-diagram",
    "consulting.3.desc": "I can help you to manage your next project with agile methods like Scrum or Kanban.",
    "consulting.4.title": "IT Integration Consulting",
    "consulting.4.icon": "fas fa-plug",
    "consulting.4.desc": "I can help you to integrate your IT services like CRM, ERP, HR, Accounting, etc., to build a custom integration or to choose the right integration platform or services.",
    "consulting.5.title": "Atlassian Jira & Confluence",
    "consulting.5.icon": "fab fa-atlassian",
    "consulting.5.desc": "I can help you to setup and configure your next Jira and Confluence instance or to build custom workflows and custom plugins and integrations with your CI/CD Pipeline.",
    "consulting.6.title": "And much more",
    "consulting.6.icon": "fas fa-ellipsis-h",
    "consulting.6.desc": "You are facing a challenge that is not listed here? No problem, just contact me and we will find a solution.",

    "cta.claim": "Interested in working with me?",
    "cta.action": "Hire me!",


    "resume.intro": "Resume",
    "resume.title": "A summary of My Resume",
    "resume.downloadCV": "Download CV",
    "resume.education.title": "My Education",
    "resume.education.1.title": "MSc. Business Informatics",
    "resume.education.1.institute": "University of Trier, Germany",
    "resume.education.1.date": "2012 - 2015",
    "resume.education.1.desc": "Focus on Software Engineering, Business Intelligence and Distributed Systems",
    "resume.education.2.title": "BSc. Business Administration",
    "resume.education.2.institute": "University of Trier, Germany",
    "resume.education.2.date": "2008 - 2012",
    "resume.education.2.desc": "Focus on Management, Finance and Controlling",


    "resume.experience.title": "My Experience",
    "resume.experience.1.title": "IT-Freelancer",
    "resume.experience.1.institute": "Self Employed / moogIT, Germany & Luxemburg",
    "resume.experience.1.date": "2023 - Present",
    "resume.experience.1.desc": "",
    "resume.experience.2.title": "CTO",
    "resume.experience.2.institute": "ITByCloud SE, Schuttrange, Luxemburg",
    "resume.experience.2.date": "2019 - 2023",
    "resume.experience.2.desc": "",
    "resume.experience.3.title": "Head of Research & Development",
    "resume.experience.3.institute": "Sine Qua Non GmbH, Frankfurt, Germany",
    "resume.experience.3.date": "2017 - 2019",
    "resume.experience.3.desc": "",
    "resume.experience.4.title": "IT Project Manager",
    "resume.experience.4.institute": "DAW SE, Ober-Ramstadt, Germany",
    "resume.experience.4.date": "2016 - 2017",
    "resume.experience.4.desc": "",
    "resume.experience.5.title": "International IT Management Trainee",
    "resume.experience.5.institute": "DAW SE, Ober-Ramstadt, Germany",
    "resume.experience.5.date": "2015 - 2016",
    "resume.experience.5.desc": "",
    "resume.experience.6.title": "IT-Freelancer",
    "resume.experience.6.institute": "Self Employed / moogIT, Trier, Germany",
    "resume.experience.6.date": "2013 - 2015",
    "resume.experience.6.desc": "",

    "contact.getintouch": "Get in touch",
    "contact.basedIn": "Based in:",
    "contact.followme": "Follow me on:",
    "contact.workingIn": "Accepting projects from:",
    "contact.luxembourg": "Luxemburg",
    "contact.worldwide": "Worldwide",
    "contact.germany": "Germany",
    "contact.name": "Tell me your name:",
    "contact.email": "Your email address:",
    "contact.send": "Send",
    "contact.sending": "Sending...",
    "contact.message": "How can I help you?",
    "contact.estimate": "Discuss your project with me:",
    "contact.successtext": "Your message has been sent successfully. I will contact you as soon as possible.",
    "contact.errortext": "An error occurred while sending your message. Please try again later.",
    "contact.text": "I'm always looking for new challenges and interesting projects. If you have a project or a challenge for me, please feel free to contact me.",

    "footer.text": "© 2023 Yannik Moog. All rights reserved",
    "footer.imprint": "Imprint",
    "footer.imprintPrivacy": "Imprint & Privacy Policy",
    "footer.cookiePolicy": "Cookie Policy",

    "imprint.title": "Imprint",
    "imprint.serviceProvider": "Service Provider",
    "imprint.serviceProviderText1": "Yannik Moog - moogIT",
    "imprint.serviceProviderText2": "Secundinierstr. 21",
    "imprint.serviceProviderText3": "54298 Igel",
    "imprint.serviceProviderText4": "Germany",
    "imprint.contact": "Contact Information",
    "imprint.contactText1": "via email at \"contact(at)moog-it.de\"",
    "imprint.contactText2": "or contact form found at \"moog-it.de\"",

    "general.backToTop": "Back to top",
    "general.switchLanguage": "German Version",
}
export default translations;