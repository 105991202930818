const translations = {
    "intro.intro": "Willkommen, ich bin",
    "intro.title1": "Yannik",
    "intro.title2": "Cloud Architekt",
    "intro.title3": "Software Entwickler",
    "intro.title4": "DevOps Ingenieur",
    "intro.title5": "IT-Berater",
    "intro.title6": "IT Freelancer",
    "intro.subtitle": "in Luxemburg und Deutschland",
    "intro.actionbutton": "Mehr erfahren",
    "intro.contactbutton": "Kontakt aufnehmen",
    "about.intro": "Über mich",
    "about.title": "Ein paar Worte über mich",
    "about.hiIam": "Hallo, ich bin",
    "about.name": "Yannik Moog",
    "about.description": "Ich bin ein erfahrener Cloud Architekt, Software Entwickler, DevOps Ingenieur und IT-Berater. Ich arbeite mit den neuesten Technologien und bin immer auf der Suche nach neuen Herausforderungen.",
    "about.yearsIntro": "Jahre",
    "about.yearsExperience": "Erfahrung",
    "about.yearsNumber": "12",
    "about.label.name": "Name:", 
    "about.value.name": "Yannik Moog",
    "about.label.age": "Geboren am:",
    "about.value.age": "14 May, 1988",
    "about.label.from": "Aus:",
    "about.value.from": "Trier, Germany",
    "about.label.email": "Email:",
    "about.label.education": "Abschluss:",
    "about.value.education": "MSc. Business Informatics",
    "about.label.speaking": "Sprachen:",
    "about.value.email": "info@moog-it.de",

    "portfolio.filter.all": "Alle",
    "portfolio.filter.consulting": "Beratung",
    "portfolio.filter.development": "Entwicklung",
    "portfolio.filter.devops": "DevOps",
    "portfolio.filter.embedded": "Embedded Systems",
    "portfolio.filter.other": "Sonstiges",

    "portfolio.p1.title": "The digital vineyard",
    "portfolio.p1.date": "2018",
    "portfolio.p1.projectInfo": "We unified separate ERP systems by digitizing wine production and integrating specific software, granting both Rotkäppchen Mumm Sektkellerein & Markgraf von Baden seamless access to vital data. Employing IoT and vineyard sensors, we captured real-time details on leaf moisture, temperature, and precipitation. These insights, analyzed in the SAP Cloud, optimized grape harvesting and maintained top-tier product quality, ensuring transparency from grape to glass. IoT integration expanded into procurement, shipping, and financial processes, streamlining the entire value chain. Our \"Connected Bottle App\" empowers consumers to trace a wine's journey, fostering transparent producer-consumer communication. This digital evolution, powered by custom software and IoT integration, seamlessly blends the Markgräflich Badischen Weinhauses' tradition with modern consumer needs.",
    "portfolio.p1.technologies": "SAP UI5, SAP Business Technology Platform, SAP Cloud Foundry, SAP IoT, Tinkerforge Hardware",
    "portfolio.p1.client": "Rotkäppchen-Mumm Sektkellereien & Markgraf von Baden via Sine Qua Non GmbH",
    "portfolio.p1.industry": "Agriculture, Wine Industry, Beverage Industry",
    "portfolio.p1.url": "https://midrange.de/der-digitale-weinberg/",

    "menu.home": "Home",
    "menu.about": "Über mich",
    "menu.services": "Dienstleistungen",
    "menu.resume": "Lebenslauf",
    "menu.portfolio": "Portfolio",
    "menu.faq": "FAQ",
    "menu.contact": "Kontakt",
    "menu.consultingServices" : "Beratungsleistungen",
    "menu.developmentServices" : "Entwicklungsleistungen",
    "menu.technologySkills" : "Technologie Skills",

    "services.intro": "Welche Entwicklungsleistungen biete ich an?",
    "services.development.title": "Software Entwicklung",
    "services.development.subtitle1": "Sie benötigen mobile oder Web-Apps, oder Backend-Dienste?",
    "services.development.subtitle2": "Oder benötigen Sie Unterstützung bei Ihrer Cloud-Infrastruktur-Bereitstellung oder CI/CD-Pipeline?",
    "services.development.1.title": "Native Mobile Apps",
    "services.development.1.icon": "fas fa-mobile-alt",
    "services.development.1.desc": "Native iOS and Android Apps mit React Native.",
    "services.development.2.title": "Single Page Apps (SPA)",
    "services.development.2.icon": "fas fa-laptop-code",
    "services.development.2.desc": "State of the Art Single Page Webapplikationen mit ReactJS, MaterialUI, Bootstrap, SASS, CSS3 und HTML5.",
    "services.development.3.title": "APIs & Backend Dienste",
    "services.development.3.icon": "fas fa-server",
    "services.development.3.desc": "API-gesteuerte Backend-Dienste mit NodeJS, Python, PHP oder JAVA. Datenbankdesign mit MySQL, MongoDB oder PostgreSQL.",
    "services.development.4.title": "DevOps Automation (CI/CD)",
    "services.development.4.icon": "fas fa-code",
    "services.development.4.desc": "DevOps Scripting mit Python, Bash. CI/CD mit Github Actions unde anderen. Docker & Kubernetes.",
    "services.development.5.title": "IoT & Embedded Systems",
    "services.development.5.icon": "fas fa-microchip",
    "services.development.5.desc": "IoT und Embedded Systems mit Arduino, Raspberry Pi, ESP32 und anderen.",
    "services.development.6.title": "Infrastructure as a Code",
    "services.development.6.icon": "fas fa-cloud",
    "services.development.6.desc": "Infrastructure as Code mit Terraform. Cloud Services mit AWS, Azure and Google Cloud.",

    "technologySkills.intro": "Welche Technologien nutze ich?",
    "technologySkills.title": "Meine technologischen Skills",
    "technologySkills.1.title": "Programmiersprachen & Frameworks",
    "technologySkills.1.1.title": "JavaScript",
    "technologySkills.1.1.desc": "ReactJS, React Native, NodeJS, NPM, ExpressJS (e.g MERN) ...",
    "technologySkills.1.1.percent": "98",
    "technologySkills.1.2.title": "Python",
    "technologySkills.1.2.desc": "Django, Flask, Pandas, Numpy, Matplotlib, Scikit-Learn, TensorFlow",
    "technologySkills.1.2.percent": "70",
    "technologySkills.1.3.title": "PHP",
    "technologySkills.1.3.desc": "Laravel, Symfony, CodeIgniter ...",
    "technologySkills.1.3.percent": "60",
    "technologySkills.1.4.title": "Java",
    "technologySkills.1.4.percent": "35",
    "technologySkills.1.4.desc": "Spring Boot, Hibernate, JPA ...",
    "technologySkills.1.5.title": "Infrastructure as Code",
    "technologySkills.1.5.percent": "73",
    "technologySkills.1.5.desc": "Terraform, CloudFormation, Ansible, Pulumi ...",
    "technologySkills.1.6.title": "OS, CI/CD, DevOps",
    "technologySkills.1.6.percent": "78",
    "technologySkills.1.6.desc": "Docker, Kubernetes, Github Actions, Linux, MacOS ...",

    "technologySkills.2.title": "Datenbanksysteme",
    "technologySkills.2.1.title": "SQL",
    "technologySkills.2.1.desc": "MySQL, PostgreSQL, MariaDB, SQLite, MS SQL Server, Oracle",
    "technologySkills.2.1.percent": "38",
    "technologySkills.2.2.title": "NoSQL",
    "technologySkills.2.2.desc": "MongoDB, DynamoDB, Redis, CouchDB, Cassandra",
    "technologySkills.2.2.percent": "72",

    "technologySkills.3.title": "Cloud Services & Plattformen",
    "technologySkills.3.1.title": "AWS",
    "technologySkills.3.1.desc": "EC2, S3, Lambda, CloudFront, API Gateway, Route53, EKS ...",
    "technologySkills.3.1.percent": "62",
    "technologySkills.3.2.title": "Google Cloud Platform",
    "technologySkills.3.2.desc": "GKE, Cloud Run, Cloud Functions, Cloud Storage ...",
    "technologySkills.3.2.percent": "91",
    "technologySkills.3.3.title": "Azure",
    "technologySkills.3.3.desc": "Azure Kubernetes Service, Azure Functions, Azure Storage ...",
    "technologySkills.3.3.percent": "28",
    "technologySkills.3.4.title": "SAP Business Technology Platform",
    "technologySkills.3.4.desc": "SAP Cloud Foundry, SAP HANA ...",
    "technologySkills.3.4.percent": "45",

    "consulting.intro": "Welche Beratungsleistungen biete ich an?",
    "consulting.title": "IT Beratung",
    "consulting.subtitle": "Mit diesen Dienstleistungen kann ich Ihnen helfen, Ihr nächstes Projekt zu realisieren.",
    "consulting.1.title": "SaaS & Cloud Lösungsarchitektur",
    "consulting.1.icon": "fas fa-server",
    "consulting.1.desc": "Ich kann Ihnen helfen, Ihre nächste SaaS-Software-Architektur aufzubauen, die richtigen Technologien und Dienste auszuwählen und eine skalierbare, sichere und zuverlässige Architektur zu erstellen.",
    "consulting.2.title": "Entwicklungs und DevOps Prozesse",
    "consulting.2.icon": "fas fa-code",
    "consulting.2.desc": "Ich kann Ihnen helfen, Ihre DevOps-Prozesse zu automatisieren, eine CI/CD-Pipeline mit Github Actions oder anderen Tools aufzubauen.",
    "consulting.3.title": "Agiles Projektmanagement & agile Softwareentwicklung",
    "consulting.3.icon": "fas fa-project-diagram",
    "consulting.3.desc": "Ich kann Ihnen helfen, Ihr nächstes Projekt mit agilen Methoden wie Scrum oder Kanban zu managen.",
    "consulting.4.title": "IT Integration Consulting",
    "consulting.4.icon": "fas fa-plug",
    "consulting.4.desc": "Ich kann Ihnen helfen, Ihre IT-Dienste wie CRM, ERP, HR, Buchhaltung usw. zu integrieren, eine maßgeschneiderte Integration zu erstellen oder die richtige Integrationsplattform oder -dienste auszuwählen.",
    "consulting.5.title": "Atlassian Jira & Confluence",
    "consulting.5.icon": "fab fa-atlassian",
    "consulting.5.desc": "Ich kann Ihnen helfen, Ihre nächste Jira- und Confluence-Instanz einzurichten und zu konfigurieren oder benutzerdefinierte Workflows, Plugins und Integrationen mit Ihrer CI/CD-Pipeline zu erstellen.",
    "consulting.6.title": "Und vieles mehr...",
    "consulting.6.icon": "fas fa-ellipsis-h",
    "consulting.6.desc": "Sie stehen vor einer Herausforderung, die hier nicht aufgeführt ist? Kein Problem, nehmen Sie einfach Kontakt mit mir auf und wir werden eine Lösung finden.",

    "cta.claim": "Interessiert an einer Zusammenarbeit?",
    "cta.action": "Nehmen Sie Kontakt auf!",


    "resume.intro": "Mein Lebenslauf",
    "resume.title": "Auszug meines Lebenslaufs",
    "resume.downloadCV": "Lebenslauf herunterladen",
    "resume.education.title": "Meine Ausbildung",
    "resume.education.1.title": "MSc. Wirtschaftsinformatik",
    "resume.education.1.institute": "Universität Trier, Deutschland",
    "resume.education.1.date": "2012 - 2015",
    "resume.education.1.desc": "Schwerpunkt auf Software Engineering, Business Intelligence und verteilte Systeme",
    "resume.education.2.title": "BSc. Betriebswirtschaftslehre",
    "resume.education.2.institute": "Universität Trier, Deutschland",
    "resume.education.2.date": "2008 - 2012",
    "resume.education.2.desc": "Fokus auf Management, Finanzen und Controlling",


    "resume.experience.title": "Meine Berufserfahrung",
    "resume.experience.1.title": "IT-Freelancer",
    "resume.experience.1.institute": "Selbstständig / moogIT, Germany & Luxemburg",
    "resume.experience.1.date": "2023 - heute",
    "resume.experience.1.desc": "",
    "resume.experience.2.title": "CTO",
    "resume.experience.2.institute": "ITByCloud SE, Schuttrange, Luxemburg",
    "resume.experience.2.date": "2019 - 2023",
    "resume.experience.2.desc": "",
    "resume.experience.3.title": "Head of Research & Development",
    "resume.experience.3.institute": "Sine Qua Non GmbH, Frankfurt, Deutschland",
    "resume.experience.3.date": "2017 - 2019",
    "resume.experience.3.desc": "",
    "resume.experience.4.title": "International IT Projektmanager",
    "resume.experience.4.institute": "DAW SE, Ober-Ramstadt, Deutschland",
    "resume.experience.4.date": "2016 - 2017",
    "resume.experience.4.desc": "",
    "resume.experience.5.title": "International IT Management Trainee",
    "resume.experience.5.institute": "DAW SE, Ober-Ramstadt, Deutschland",
    "resume.experience.5.date": "2015 - 2016",
    "resume.experience.5.desc": "",
    "resume.experience.6.title": "IT-Freelancer",
    "resume.experience.6.institute": "Selbstständig / moogIT, Trier, Germany",
    "resume.experience.6.date": "2013 - 2015",
    "resume.experience.6.desc": "",

    "contact.getintouch": "Kontakt aufnehmen",
    "contact.basedIn": "Ich arbeite von hier aus:",
    "contact.followme": "Folgen Sie mir auf:",
    "contact.workingIn": "Nehme Projekte an:",
    "contact.luxembourg": "Luxemburg",
    "contact.worldwide": "Weltweit",
    "contact.germany": "Deutschland",
    "contact.name": "Ihr Name:",
    "contact.email": "Ihre Email-Adresse:",
    "contact.send": "Senden",
    "contact.sending": "Senden...",
    "contact.message": "Wie kann ich Ihnen helfen?",
    "contact.estimate": "Besprechen Sie Ihr Projekt mit mir",
    "contact.successtext": "Ihre Nachricht wurde erfolgreich abgeschickt. Ich werde Sie so bald wie möglich kontaktieren.",
    "contact.errortext": "Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    "contact.text": "Ich bin immer auf der Suche nach neuen Herausforderungen und interessanten Projekten. Wenn Sie ein Projekt oder eine Herausforderung für mich haben, können Sie mich gerne kontaktieren.",

    "footer.text": "© 2023 Yannik Moog. Alle Rechte vorbehalten.",
    "footer.imprint": "Impressum",
    "footer.imprintPrivacy": "Impressum & Datenschutzerklärung",
    "footer.cookiePolicy": "Cookie-Richtlinie",

    "imprint.title": "Impressum",
    "imprint.serviceProvider": "Diensteanbieter",
    "imprint.serviceProviderText1": "Yannik Moog - moogIT",
    "imprint.serviceProviderText2": "Secundinierstr. 21",
    "imprint.serviceProviderText3": "54298 Igel",
    "imprint.serviceProviderText4": "Deutschland",
    "imprint.contact": "Kontakt",
    "imprint.contactText1": "via email \"contact(at)moog-it.de\"",
    "imprint.contactText2": "oder das Kontaktformular unter \"moog-it.de\"",

    "general.backToTop": "Zurück nach oben",
    "general.switchLanguage": "Englische Version",
}
export default translations;