import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./de.js";
import en from "./en.js";

import { universalLanguageDetect } from '@unly/universal-language-detector';

const lang = universalLanguageDetect({
  supportedLanguages: ["de","en"], // Whitelist of supported languages, will be used to filter out languages that aren't supported
  fallbackLanguage: "en", // Fallback language in case the user's language cannot be resolved
});


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  de: {
    translation: de
  },
  en: {
    translation: en
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;