import "./App.scss";
import WOW from "wowjs";



/*
import AboutUs from "./components/About";
import Services from "./components/Services";
import Consulting from "./components/Consulting";
import Resume from "./components/Resume";
import TechnologySkills from "./components/TechnologySkills";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

import CallToAction from "./components/CallToAction";
import FAQs from "./components/FAQs";
import Intro from "./components/Intro";
//import { CookieBanner } from '@schlomoh/react-cookieconsent'
*/




import { useEffect, useState } from "react";

import { Tooltip } from "./components/common/Tooltip";
import Header from "./components/pageblocks/Header";
import Footer from "./components/pageblocks/Footer";

import SectionsBlock from "./components/sections";
import SectionsConfig from "./constants/Sections";

import { useTranslation } from 'react-i18next';
 
function App() {
  
  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const { t } = useTranslation();

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <> 
      <div style={{ position: "relative" }}>
        <div id="main-wrapper">
          <Header sections={SectionsConfig} handleNavClick={handleNavClick} />
          <div id="content" role="main">
            <SectionsBlock sections={SectionsConfig} handleNavClick={handleNavClick} />
          </div>
          <Footer handleNavClick={handleNavClick}></Footer>
        </div>
        {/* back to top */}
        <Tooltip text={t("general.backToTop")} placement="right">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fas fa-arrow-up"></i>
          </span>
        </Tooltip>
      </div>
    </>
  );
}

export default App;
