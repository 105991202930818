import React from "react";
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">
            {t('about.intro')}
          </span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          {t('about.title')}
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              {t('about.hiIam')}{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                {t('about.name')}
              </span>
            </h2>
            <p className="text-5">
              {t('about.description')}
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  {t('about.yearsNumber')}
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                {t('about.yearsIntro')}{" "}
                <span className="fw-700">
                {t('about.yearsExperience')}
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">
              {t('about.label.name')}
            </p>
            <p className="text-4 text-dark fw-600 mb-0">
              {t('about.value.name')}
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">
              {t('about.label.education')}
            </p>
            <p className="text-4 fw-600 mb-0">
                {t('about.value.education')}
            </p>
          </div>
          
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">
              {t('about.label.from')}
            </p>
            <p className="text-4 text-dark fw-600 mb-0">
              {t('about.value.from')}
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">
              {t('about.label.speaking')}
            </p>
            <p className="text-4 text-dark fw-600 mb-0">
              <ReactCountryFlag countryCode="DE" svg /> {" & "}
              <ReactCountryFlag countryCode="US" svg />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
