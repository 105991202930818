import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from 'react-i18next';
import SocialLinks from "../common/SocialLinks";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();

    setSendingMail(true);
    emailjs
      .sendForm(
        "service_z5lnlfe",
        "template_i1h6btw",
        form.current,
        "XNA-8fZ5_ht5klXrr"
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          toast.success(
            t("contact.successtext"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSendingMail(false);
        },
        (error) => {
          toast.error(
            t("contact.errortext"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSendingMail(false);
        }
      );
  };

  return (
    <section id="contact" className="section bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Let's get in touch</h2>
            <p className="text-5 mb-5">
              {t("contact.text")}
            </p>
            <div className="row">
              <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
                <h3 className="text-5 fw-600">
                  {t("contact.basedIn")}
                </h3>
                  <address className="text-4">
                  <ReactCountryFlag countryCode="DE" svg />
                  {"  "}{t("contact.germany")}{ " & "}
                  &nbsp;<br></br>
                  <ReactCountryFlag countryCode="LU" svg />
                  {"  "}{t("contact.luxembourg")}
                </address>
              </div>
              <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
                <h3 className="text-5 fw-600">
                  {t("contact.workingIn")}
                </h3>
                <address className="text-4">
                  &#127758;
                  {"  "}{t("contact.worldwide")}
                </address>
              </div>
            
            </div>

            <div className="row">
              <h3 className="text-5 fw-600 mt-4">
                  {t("contact.followme")}
              </h3>
              <SocialLinks/>
              
            </div>
            
            
            
          </div>
          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              {t("contact.estimate")}
            </h2>
            {/* Contact Form */}
            <form
              id="contact-form"
              className="form-border"
              method="post"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-12">
                  <label className="form-label" htmlFor="name">
                    {t("contact.name")}
                  </label>
                  <input
                    id="name"
                    name="user_name"
                    type="text"
                    className="form-control py-1"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="email">
                  {t("contact.email")}
                  </label>
                  <input
                    id="email"
                    name="user_email"
                    type="email"
                    className="form-control py-1"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="form-message">
                    {t("contact.message")}
                    
                  </label>
                  <textarea
                    id="form-message"
                    name="message"
                    className="form-control py-1"
                    rows={4}
                    required
                    defaultValue={""}
                  />
                </div>
                <div className="col-12 text-center text-lg-start">
                  <button
                    id="submit-btn"
                    className="btn btn-dark rounded-0"
                    type="submit"
                  >
                    {sendingMail ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        {
                          t("contact.sending")
                        }
                      </>
                    ) : (
                      <>
                        {t("contact.send")}{" "}
                        <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                      </>
                    )}
                  </button>
                </div>
                <ToastContainer />
              </div>
            </form>
            {/* Contact Form end */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
