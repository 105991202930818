import React, { useState } from "react";
import { Link } from "react-scroll";
import { scrollDuration } from "../../config/commonConfig";

import SocialLinks from "../common/SocialLinks";
import { useTranslation } from 'react-i18next';

const LinkItem = ({ target, t, setIsNavModalClose }) => {
  return (
    <li className="nav-item">
      <Link
        className="nav-link "
        smooth="easeInOutQuint"
        duration={scrollDuration}
        style={{ cursor: "pointer" }}
        activeClass="active"
        spy
        to={target}
        onClick={(e) => {
          e.preventDefault();
          setIsNavModalClose(true);
        }}
      >
        {t('menu.'+target)}
      </Link>
    </li>
  )
}

const MainLogo = ({ stickyHeader, setIsNavModalClose }) => {
  return (
    <Link
      className="logo"
      title="moogIT"
      smooth="easeInOutQuint"
      duration={scrollDuration}
      style={{ cursor: "pointer" }}
      to="home"
      onClick={(e) => {
        e.preventDefault();
        setIsNavModalClose(true);
      }}
    >
      <img
        src={stickyHeader ? "images/yannikmoog-logo.png" : "images/yannikmoog-logo-light.png"}
        //src={"images/yannikmoog-logo.png"}
        alt="Yannik Moog"
      />
    </Link>
  )
}

const MenuButton = ({ isNavModalClose, setIsNavModalClose }) => {
  return (
        <button
            className={
              "navbar-toggler collapsed " + (isNavModalClose ? "" : "show")
            }
            type="button"
            onClick={() => setIsNavModalClose(!isNavModalClose)}
          >
            <span />
            <span />
            <span />
          </button>
  )
}

const LanguageButton = ({ setIsNavModalClose }) => {
  const { i18n, t } = useTranslation();
  return (
    <div className="text-3 mt-3">
      <div className="btn-group">
        {/* Button without border */}
        <button
          style={{color: "white"}}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
            setIsNavModalClose(true);
          }}
          className={
            "btn btn-sm"
          }
          aria-expanded="false"
        >
          {/* Font Awesome Icon */}
          <i className="fas fa-globe me-2" />
          {t('general.switchLanguage')}
        </button>
      </div>
    </div>
  );
};

const FullScreenHeader = ({ textWhite, sections }) => {
  const [stickyHeader, setStickyHeader] = useState(false);
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  const { t } = useTranslation();

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 1 ||
        document.documentElement.scrollTop > 1
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-none navbar-overlay  bg-transparent border-bottom-0 text-5 fw-600 " +
          (stickyHeader ? "sticky-on-top " : " ") +
          (textWhite
            ? "navbar-overlay navbar-text-light"
            : "navbar-overlay-dark")
        }
      >
        <div className="container">
          <MainLogo stickyHeader={stickyHeader} setIsNavModalClose={setIsNavModalClose} />
          <MenuButton isNavModalClose={isNavModalClose} setIsNavModalClose={setIsNavModalClose} />
          <div
            id="header-nav"
            className={
              "collapse navbar-collapse " + (isNavModalClose ? "" : "show")
            }
          >
            <div className="d-flex flex-column h-100 align-items-center justify-content-center">
              <ul className="navbar-nav">
                {
                  sections.map((section) => {
                    if(!section.renderMenu) {
                      return null;
                    }
                    return (
                      <LinkItem target={section.section} t={t} setIsNavModalClose={setIsNavModalClose} />
                    )
                  })
                }
              </ul>
              {/* Separator */}
              <hr className="w-100" style={{color: "white", maxWidth: "30vw"}} />
              {/* End Separator */}
              {/* Social Links */}
              <SocialLinks isMenu={true} />
              <hr className="w-100" style={{color: "white", maxWidth: "30vw"}} />
              <LanguageButton textWhite={textWhite} setIsNavModalClose={setIsNavModalClose} />
            </div>
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default FullScreenHeader;
