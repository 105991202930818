import SocialLinks from "../../constants/SocialLinks";
import { Tooltip } from "./Tooltip";

function SocialLinksBanner({isMenu}) {
    const outerClasses = isMenu ? 
        "social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-1": 
        "social-icons social-icons-lg justify-content-center justify-content-lg-start mt-2";
    return (
        <ul className={outerClasses}>
            {SocialLinks.map((link) => {
                return (
                    <li className={link.className}>
                        <Tooltip text={link.title} placement="top">
                            <a
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                            <i class={link.icon}></i>
                            </a>
                        </Tooltip>
                    </li>
                )
            })}
        </ul>
    )
} 

export default SocialLinksBanner;